import { useLocation } from "@reach/router";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import * as React from "react";
import IndexJsonLd from "../components/IndexjsonLd";
import LoadingCircular from "../components/loadingCircular";
import { getInfoAboutTheArea } from "../helpers/areaInfo";
import { default as Seo } from "../layouts/head";
import Layout from "../layouts/pc";
import LayoutSP from "../layouts/sp";
import Top from "../templates/pc/top";
import TopSP from "../templates/sp/top";
import { AREA_NAME } from "../utils/Area";

const IndexPage = () => {
  // if (process.env.GATSBY_COUNTDOWN) {
  //   navigate("/countdown");
  // }

  const breakpoints = useBreakpoint();
  const [loading, setLoading] = React.useState(true);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const areaName = params.get("areaName")
    ? params.get("areaName")
    : AREA_NAME.JAPAN;
  const aboutAreaInfo = getInfoAboutTheArea(areaName);
  const title = `${aboutAreaInfo.japaneseAreaName}のビジネスモデル・Webメディア｜ロートリ 地方の面白いビジネスモデルを紹介`;

  React.useEffect(() => {
    setTimeout(() => setLoading(false), 50);
  }, []);
  return (
    <>
      <Seo title={title} />
      <IndexJsonLd title={title} />
      {loading ? (
        <LoadingCircular />
      ) : breakpoints.sm ? (
        <LayoutSP>
          <TopSP aboutAreaInfo={aboutAreaInfo} />
        </LayoutSP>
      ) : (
        <Layout>
          <Top aboutAreaInfo={aboutAreaInfo} />
        </Layout>
      )}
    </>
  );
};

export default IndexPage;
