import { Typography } from "@mui/material";
import { graphql, useStaticQuery } from "gatsby";

import React from "react";
import styled, { css } from "styled-components";
import { default as BaseAreaSearch } from "../../components/areaSearch";
import CatchCopy from "../../components/catchCopy";
import CompanyIndex from "../../components/companyIndex";
import { default as BaseContact } from "../../components/contact";
import { getAreaMapComponent } from "../../helpers/areaInfo";
import topBackground from "../../images/background.png";
import background from "../../images/billdingBackground.png";
import pickUpBackgroundBlue from "../../images/pickUpBackgroundBlue.png";
import pickUpBackgroundYellow from "../../images/pickUpBackgroundYellow.png";

const Top = ({ aboutAreaInfo }) => {
  const data = useStaticQuery(graphql`
    query allContentfulServiceIndex {
      allContentfulServiceIndex(
        sort: { order: DESC, fields: createdAt }
        limit: 3
      ) {
        ...contentsData
      }
    }
  `);

  return (
    <MainDiv>
      <TopDiv>
        <MapDiv>
          <Area>{getAreaMapComponent(aboutAreaInfo.areaName)}</Area>
          <WhiteBord
            src={aboutAreaInfo.whitebordImage}
            alt="whitebord"
          ></WhiteBord>
        </MapDiv>
        <AreaSearchButtonDiv>
          <AreaSearch
            to={`/area/?areaName=${aboutAreaInfo.areaName}`}
            areaName={aboutAreaInfo.japaneseAreaName}
            areaNameID={aboutAreaInfo.areaName}
          />
        </AreaSearchButtonDiv>
        <BackGroundDiv>
          <BackGround src={background} alt={"billdingBackground"} />
        </BackGroundDiv>
      </TopDiv>

      <PickUpDiv>
        <PickUpBackgroundDiv>
          <PickUpBackgroundYellow></PickUpBackgroundYellow>
          <PickUpBackgroundBlue></PickUpBackgroundBlue>
        </PickUpBackgroundDiv>
        <PickUpContentsDiv>
          <PickUpText>PICK UP</PickUpText>
          <PickUpSubText>今注目のビジネスモデル</PickUpSubText>
          <PickUpBackground>
            <ContentsDiv>
              {data.allContentfulServiceIndex.edges.map((props) => {
                return <CompanyIndex key={props.node.id} {...props} />;
              })}
            </ContentsDiv>
          </PickUpBackground>
        </PickUpContentsDiv>
      </PickUpDiv>
      <CatchCopyDiv>
        <CatchCopy />
      </CatchCopyDiv>
      <ContactDiv>
        <Contact />
      </ContactDiv>
    </MainDiv>
  );
};

const MainDiv = styled.div`
  background-image: url(${topBackground});
`;

const MapDiv = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 36px;
  gap: 10%;
`;

const TopDiv = styled.div`
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
  display: block;
  width: 100vw;
`;

const ContactDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 70px;
  padding-bottom: 70px;
`;

const Contact = styled(BaseContact)``;

const BackGroundDiv = styled.div`
  margin-top: 36px;
`;

const PickUpBackgroundDiv = styled.div`
  position: relative;
  z-index: 1;
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
  display: block;
  width: 100vw;
`;

const PickUpDiv = styled.div``;

const ContentsDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
  gap: 70px;
`;

const PickUpContentsDiv = styled.div`
  position: relative;
  z-index: 99;
  padding-top: 70px;
`;

const CatchCopyDiv = styled.div`
  margin-top: 70px;
  padding-top: 70px;
  text-align: center;
`;

const Area = styled.div`
  width: 30%;
`;

const WhiteBord = styled.img`
  width: 30%;
`;

const AreaSearchButtonDiv = styled.div`
  margin-top: 36px;
`;

const AreaSearch = styled(BaseAreaSearch)``;

const PickUpBackgroundBlue = styled.div`
  z-index: -1;
  position: absolute;
  background-image: url(${pickUpBackgroundBlue});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 700px;
`;

const PickUpBackgroundYellow = styled.div`
  z-index: -1;
  position: absolute;
  background-image: url(${pickUpBackgroundYellow});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 700px;
`;

const PickUpBackground = styled.div`
  padding: 0 32px;
`;

const PickUpText = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.fonts.size.xl4};
    font-weight: ${theme.fonts.weight.bold};
    letter-spacing: ${theme.fonts.spacing.primary};
    color: ${theme.colors.black.primary};
    text-align: center;
    line-height: 48px;
  `}
`;

const PickUpSubText = styled(Typography)`
  ${({ theme }) => css`
    margin-top: 5px;
    font-size: ${theme.fonts.size.xl2};
    font-weight: ${theme.fonts.weight.bold};
    letter-spacing: ${theme.fonts.spacing.primary};
    color: ${theme.colors.black.primary};
    text-align: center;
    line-height: 32px;
  `}
`;

const BackGround = styled.img`
  display: block;
  width: 100%;
  height: auto;
`;

export default Top;
