import { Button as BaseButton } from "@mui/material";
import { Link } from "gatsby";
import React from "react";
import styled, { css } from "styled-components";
import { AREA_NAME } from "../utils/Area";

const Button = ({
  className,
  children,
  areaNameID = AREA_NAME.JAPAN,
  issp,
  ...props
}) => {
  return (
    <BaseButton
      className={className}
      id={"area-search-button-click"}
      gtm-area-search-button={
        issp ? `area-search-button-sp` : `area-search-button`
      }
      component={Link}
      to={props.to}
    >
      {children}
    </BaseButton>
  );
};

const AreaSearchButton = styled(Button)`
  ${({ theme }) => css`
    background-color: ${theme.colors.orange.primary};
    border-color: ${theme.colors.white.primary};
    color: ${theme.colors.white.primary};
    font-weight: ${theme.fonts.weight.bold};
    font-size: ${theme.fonts.size.base};
    letter-spacing: ${theme.fonts.spacing.primary};
    border-radius: 10px;
    padding: 15px 22px;
    &:hover {
      background-color: ${theme.colors.orange.primary};
      border-color: ${theme.colors.white.primary};
      box-shadow: none;
    }
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.4);
  `}
`;

export default AreaSearchButton;
