import { Search } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { useLocation } from "@reach/router";
import { graphql, navigate, useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { default as BaseAreaSearchButton } from "../../components/areaSearchButton";
import { default as BaseAreaSelectButtonSP } from "../../components/sp/areaSelectButtonSP";
import CatchCopySP from "../../components/sp/catchCopySP";
import { default as BaseCompanyIndexSP } from "../../components/sp/companyIndexSP";
import { default as BaseContactSP } from "../../components/sp/contactSP";
import { getAreasSP, getPreviousArea } from "../../helpers/areaInfo";
import background from "../../images/background.png";
import billdingBackground from "../../images/billdingBackground.png";
import pickUpBackground from "../../images/pickUpBackground.svg";
import { AREA_NAME } from "../../utils/Area";

const TopSP = ({ aboutAreaInfo }) => {
  const location = useLocation();
  const [areaNames, setAreaNames] = useState([]);

  const data = useStaticQuery(graphql`
    query allContentfulServiceIndex {
      allContentfulServiceIndex(
        sort: { order: DESC, fields: createdAt }
        limit: 3
      ) {
        ...contentsData
      }
    }
  `);

  useEffect(() => {
    setAreaNames(getAreasSP(aboutAreaInfo.areaName));
  }, [aboutAreaInfo]);

  const onClickBack = () => {
    const previousArea = getPreviousArea(aboutAreaInfo.areaName);
    navigate(`${location.pathname}?areaName=${previousArea.areaName}`);
  };

  return (
    <>
      <MainDiv>
        <FirstDiv>
          <MapDiv>
            <Area
              src={aboutAreaInfo.areaImageSP}
              alt={aboutAreaInfo.areaName}
            />
            <AreaDescriptionDiv>
              <AreaNameText>{aboutAreaInfo.japaneseAreaName}</AreaNameText>
              <RepresentativeAreaText>
                {aboutAreaInfo.majorCityDecription}
              </RepresentativeAreaText>
              <AreaDescriptionText>
                {aboutAreaInfo.areaInfoDescriptionText}
              </AreaDescriptionText>
            </AreaDescriptionDiv>
          </MapDiv>
          <Line />
          {aboutAreaInfo.areaName !== AREA_NAME.JAPAN && (
            <BackButton onClick={onClickBack}>戻る</BackButton>
          )}
          <AreaSearchDiv>
            {areaNames.map((props) => {
              return (
                <AreaSelectButtonSP
                  key={props.japaneseAreaName}
                  currentAreaName={aboutAreaInfo.areaName}
                  {...props}
                  gtmID={`area-select-button-sp`}
                />
              );
            })}
          </AreaSearchDiv>
          <AreaSearchButtonDiv>
            <AreaSearchButton
              to={`/area/?areaName=${aboutAreaInfo.areaName}`}
              areaName={aboutAreaInfo.japaneseAreaName}
              areaNameID={aboutAreaInfo.areaName}
              issp={true}
            >
              <Search /> {aboutAreaInfo.japaneseAreaName}のビジネスを探す
            </AreaSearchButton>
          </AreaSearchButtonDiv>
        </FirstDiv>
        <BackGroundDiv>
          <BackGround src={billdingBackground} alt={"billdingBackground"} />
        </BackGroundDiv>
        <PickUpDiv>
          <PickUpBackgroundDiv>
            <PickUpBackground />
          </PickUpBackgroundDiv>
          <PickUpTextDiv>
            <PickUpText>PICK UP</PickUpText>
            <PickUpSubText>今注目のビジネスモデル</PickUpSubText>
          </PickUpTextDiv>
          <ServiceDiv>
            {data.allContentfulServiceIndex.edges.map((props) => {
              return <CompanyIndexSP key={props.node.id} {...props} />;
            })}
          </ServiceDiv>
        </PickUpDiv>
        <CatchCopyDiv>
          <CatchCopySP />
        </CatchCopyDiv>

        <ContactDiv>
          <ContactSP />
        </ContactDiv>
      </MainDiv>
    </>
  );
};

const MainDiv = styled.div`
  padding: 0 5%;
  background-image: url(${background});
`;

const MapDiv = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
`;

const CatchCopyDiv = styled.div`
  position: relative;
  z-index: 99;
  margin-top: 40px;
  text-align: center;
`;

const AreaDescriptionDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
const ContactDiv = styled.div`
  position: relative;
  z-index: 99;
  display: flex;
  justify-content: center;
  margin-top: 40px;
  padding-bottom: 40px;
`;

const ContactSP = styled(BaseContactSP)`
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
  display: block;
  width: 100vw;
`;
const Area = styled.img`
  width: 100px;
  height: 100px;
  margin-right: 20px;
`;
const BackGroundDiv = styled.div`
  margin-top: 36px;
`;
const BackGround = styled.img`
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
  display: block;
  width: 100vw;
  height: 100%;
`;

const Line = styled.div`
  ${({ theme }) => css`
    width: 100%;
    border-top: 2px dashed ${theme.colors.gray.secondary};
  `}
`;

const BackButton = styled(Button)`
  ${({ theme }) => css`
    margin-top: 15px;
    background-color: ${theme.colors.white.primary};
    font-size: ${theme.fonts.size.sm};
    color: ${theme.colors.black.primary};
    letter-spacing: ${theme.fonts.spacing.primarySP};
    border-radius: 10px;
    &:hover {
      background-color: ${theme.colors.white.primary};
    }
  `}
`;

const AreaSearchDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 160px);
  justify-content: center;
  grid-gap: 1rem;
  padding-top: 15px;
`;

const AreaSelectButtonSP = styled(BaseAreaSelectButtonSP)`
  /* width: calc(50% - 22px); */
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
`;

const AreaNameText = styled(Typography)`
  ${({ theme }) => css`
    font-weight: ${theme.fonts.weight.bold};
    font-size: ${theme.fonts.size.xlsp};
    letter-spacing: ${theme.fonts.spacing.primary};
    color: ${theme.colors.black.primary};
  `}
`;
const RepresentativeAreaText = styled(Typography)`
  ${({ theme }) => css`
    font-weight: ${theme.fonts.weight.bold};
    font-size: ${theme.fonts.size.sm};
    letter-spacing: ${theme.fonts.spacing.primary};
    color: ${theme.colors.black.primary};
  `}
`;
const AreaDescriptionText = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.fonts.size.sm};
    letter-spacing: ${theme.fonts.spacing.primary};
    color: ${theme.colors.black.primary};
  `}
`;
const AreaSearchButtonDiv = styled.div`
  margin-top: 20px;
  text-align: center;
`;
const AreaSearchButton = styled(BaseAreaSearchButton)``;

const PickUpDiv = styled.div`
  margin-top: 40px;
`;

const PickUpTextDiv = styled.div`
  position: relative;
  z-index: 99;
  margin-bottom: 40px;
`;
const PickUpBackgroundDiv = styled.div`
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
  display: block;
`;
const PickUpBackground = styled.div`
  position: absolute;
  z-index: 1;
  background-image: url(${pickUpBackground});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 560px;
  max-width: 960px;
`;

const ServiceDiv = styled.div`
  position: relative;
  z-index: 99;
`;
const CompanyIndexSP = styled(BaseCompanyIndexSP)`
  margin-bottom: 40px;
`;

const PickUpText = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.fonts.size.xlsp};
    font-weight: ${theme.fonts.weight.bold};
    letter-spacing: ${theme.fonts.spacing.primarySP};
    color: ${theme.colors.black.primary};
    text-align: center;
  `}
`;

const PickUpSubText = styled(Typography)`
  ${({ theme }) => css`
    margin-top: 5px;
    font-size: ${theme.fonts.size.sm};
    letter-spacing: ${theme.fonts.spacing.primarySP};
    color: ${theme.colors.black.primary};
    text-align: center;
  `}
`;

const FirstDiv = styled.div`
  padding-top: 20px;
`;

export default TopSP;
