import { Search } from "@mui/icons-material";
import { Typography } from "@mui/material";
import React from "react";
import styled, { css } from "styled-components";
import circle from "../images/circle.svg";
import coinTree from "../images/coinTree.svg";
import moneyTree from "../images/moneyTree.svg";
import square from "../images/square.svg";
import triangle from "../images/triangle.svg";
import { AREA_NAME } from "../utils/Area";
import AreaSearchButton from "./areaSearchButton";

const AreaSearch = ({ className, areaNameID = AREA_NAME.JAPAN, ...props }) => {
  return (
    <MainDiv className={className}>
      <MoneyTreeImg src={moneyTree} alt="moneyTree" />
      <ProfileDiv>
        <HeadlineText>面白いビジネルモデル例</HeadlineText>
        <ExampleTextDiv>
          <TextDiv>
            <img src={circle} alt="circle" />
            <BodyText>リンゴから生まれたレザー</BodyText>
          </TextDiv>
          <TextDiv>
            <img src={triangle} alt="triangle" />
            <BodyText>コオロギが世界を救う！！</BodyText>
          </TextDiv>
          <TextDiv>
            <img src={square} alt="square" />
            <BodyText>思い出を次に繋ぐ古着屋</BodyText>
          </TextDiv>
        </ExampleTextDiv>
      </ProfileDiv>
      <ButtonAreaDiv>
        <CoinTreeImg src={coinTree} alt="coinTree"></CoinTreeImg>
        <ButtonDiv>
          <AreaSearchButton to={props.to} areaNameID={areaNameID}>
            <Search /> {props.areaName}のビジネスを探す
          </AreaSearchButton>
        </ButtonDiv>
      </ButtonAreaDiv>
    </MainDiv>
  );
};

const MainDiv = styled.div`
  ${({ theme }) => css`
    border-radius: 10px;
    margin: auto;
    display: flex;
    background-color: ${theme.colors.white.primary};
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding: 25px 30px;
  `}
`;

const CoinTreeImg = styled.img`
  width: 140px;
`;

const MoneyTreeImg = styled.img`
  margin-right: 30px;
  width: 73px;
`;

const ExampleTextDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const TextDiv = styled.div`
  display: flex;
  gap: 10px;
`;

const ProfileDiv = styled.div`
  margin-right: 60px;
`;

const ButtonDiv = styled.div`
  margin-top: 10px;
`;

const ButtonAreaDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeadlineText = styled(Typography)`
  ${({ theme }) => css`
    font-weight: ${theme.fonts.weight.bold};
    font-size: ${theme.fonts.size.xl2};
    color: ${theme.colors.black.primary};
    letter-spacing: ${theme.fonts.spacing.primary};
  `}
`;

const BodyText = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.fonts.size.base};
    letter-spacing: ${theme.fonts.spacing.primary};
    color: ${theme.colors.black.primary};
  `}
`;

export default AreaSearch;
