import { Typography } from "@mui/material";
import { Link } from "gatsby";
import React from "react";
import styled, { css } from "styled-components";
import { urlInfo } from "../helpers/url";
import instagram from "../images/sns/instagram-icon.png";
import tiktok from "../images/sns/tiktok-icon.png";
import twitter from "../images/sns/twitter-icon.png";
import youtube from "../images/sns/youtube-icon.png";

const BaseCatchCopy = ({ className, ...props }) => {
  return (
    <MainDiv className={className}>
      <BannerHeadlineText>Lagopus</BannerHeadlineText>
      <HeadlineText>ふるさとのためにできることを。</HeadlineText>
      <SnsDiv>
        <a target="_blank" href={urlInfo.twitter} rel="noreferrer">
          <SnsLogo src={twitter} alt="twitterLogo" />
        </a>
        <a target="_blank" href={urlInfo.instagram} rel="noreferrer">
          <SnsLogo src={instagram} alt="instagramLogo" />
        </a>
        <a target="_blank" href={urlInfo.tiktok} rel="noreferrer">
          <SnsLogo src={tiktok} alt="tiktokLogo" />
        </a>
        <a target="_blank" href={urlInfo.youtube} rel="noreferrer">
          <SnsLogo src={youtube} alt="youtubeLogo" />
        </a>
      </SnsDiv>
      <MoreInfo component={Link} to="/about-us/">
        もっと見る →
      </MoreInfo>
    </MainDiv>
  );
};

const MainDiv = styled.div``;

const SnsLogo = styled.img`
  width: 100px;
`;

const SnsDiv = styled.div`
  display: flex;
  margin: 50px 0;
  justify-content: center;
  gap: 75px;
`;

const BannerHeadlineText = styled(Typography)`
  ${({ theme }) => css`
    font-weight: ${theme.fonts.weight.bold};
    font-size: ${theme.fonts.size.xl4};
    letter-spacing: ${theme.fonts.spacing.primary};
    color: ${theme.colors.black.primary};
    line-height: 48px;
  `}
`;

const HeadlineText = styled(Typography)`
  ${({ theme }) => css`
    font-weight: ${theme.fonts.weight.bold};
    font-size: ${theme.fonts.size.xl2};
    letter-spacing: ${theme.fonts.spacing.primary};
    color: ${theme.colors.black.primary};
    line-height: 32px;
  `}
`;

const MoreInfo = styled(HeadlineText)`
  ${({ theme }) => css`
    text-align: center;
    position: relative;
    display: inline-block;
    margin-bottom: 15px;
    color: ${theme.colors.black.primary};
    text-decoration: none;

    &:before {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -15px;
      display: inline-block;
      width: 246px;
      height: 2px;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%); /*位置調整*/
      background-color: ${theme.colors.black.primary};
    }
  `}
`;

const CatchCopy = styled(BaseCatchCopy)``;
export default CatchCopy;
